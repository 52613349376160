@charset "UTF-8";
/*
* Third Party
*/
@import url(../../bower_components/normalize.css/normalize.css);
/*
* Custom
*/
@import url(partials/reset.css);
@import url(partials/bootstrap.min.css);
@import url(partials/font-awesome.css);
@import url(partials/owl.carousel.css);
@import url(partials/imagelightbox.css);
/* Fonts */
/* Mixins */
@import url("https://fonts.googleapis.com/css?family=Lato:400,900,700,700i|Playfair+Display:400,400i,700i,600,700,900");
@keyframes shake {
  0%, 100% {
    margin-left: 0px; }
  50% {
    margin-left: -5px; } }

@keyframes shake2 {
  0%, 100% {
    margin-right: 0px; }
  50% {
    margin-right: -5px; } }

/* Fonts */
/* Mixins */
@keyframes shake {
  0%, 100% {
    margin-left: 0px; }
  50% {
    margin-left: -5px; } }

@keyframes shake2 {
  0%, 100% {
    margin-right: 0px; }
  50% {
    margin-right: -5px; } }

/* Fonts */
/*
font-family: $Playfair;
font-family: $Lato;
*/
/* Media Screens */
.container {
  width: 970px;
  margin: 0 auto; }
  @media (min-width: 1600px) {
    body .container {
      width: 1270px; } }
  @media (min-width: 1330px) {
    .container {
      width: 1170px; } }
  @media (max-width: 992px) {
    .container {
      width: 721px; } }
  @media (max-width: 767px) {
    .container {
      width: 100%; } }
  @media (max-width: 479px) {
    .container {
      width: 100%;
      min-width: 320px; } }

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #fff; }

body {
  font-size: 18px;
  line-height: 1.5em;
  color: #292b2e;
  font-weight: 400;
  font-family: "Lato", sans-serif; }
  @media (max-width: 1329px) {
    body {
      font-size: 15px; } }
  @media (max-width: 479px) {
    body {
      min-width: 320px;
      font-size: 14px; } }

.site-container {
  max-width: 3000px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  background: #fff;
  background-size: 100%; }

.front-end {
  background: #ccc;
  border-radius: 5px; }
  @media (max-width: 479px) {
    .front-end {
      background: #fff; } }

/**   nav menu  **/
#nav {
  background: #2b2b2b; }
  @media (max-width: 767px) {
    #nav {
      display: none; } }
  #nav:before, #nav:after {
    content: " ";
    display: table; }
  #nav:after {
    clear: both; }
  #nav .sf-menu {
    position: relative;
    font-size: 0;
    line-height: 0;
    border-radius: 0 0 4px 4px;
    white-space: nowrap;
    text-align: center; }
    #nav .sf-menu > li {
      margin: 0 10px;
      padding: 0;
      position: relative;
      display: inline-block;
      font-size: 0;
      line-height: 0; }
      #nav .sf-menu > li > a {
        font-size: 16px;
        line-height: 1.4;
        color: #d8d8d8;
        font-weight: 700;
        text-decoration: none;
        display: inline-block;
        margin: 0 20px;
        padding: 15px 0;
        transition: .2s ease-in-out;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-bottom: 3px transparent solid;
        letter-spacing: 0.9px; }
        @media (max-width: 1329px) {
          #nav .sf-menu > li > a {
            font-size: 14px; } }
        @media (max-width: 992px) {
          #nav .sf-menu > li > a {
            font-size: 14px;
            font-weight: 400; } }
      #nav .sf-menu > li.sfHover > a, #nav .sf-menu > li:hover > a {
        color: #fff;
        border-bottom-color: #be1e2d; }
      #nav .sf-menu > li > a.current-menu-item {
        color: #fff; }
      #nav .sf-menu > li.has-menu > a::before {
        content: '';
        display: block;
        float: right;
        font-family: "FontAwesome";
        font-size: 16px;
        color: #d7d7d7;
        margin-left: 10px;
        font-weight: 400;
        margin-right: -10px; }
        @media (max-width: 1329px) {
          #nav .sf-menu > li.has-menu > a::before {
            display: none; } }
    #nav .sf-menu > li + li:before {
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-bottom: 7px solid #bfbfbf;
      position: relative;
      top: 18px;
      opacity: 0.4;
      content: '';
      left: -10px; }
    #nav .sf-menu > li + li:after {
      content: '';
      position: absolute;
      left: -10px;
      top: 25px;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top: 7px solid #bfbfbf;
      opacity: 0.4; }
    #nav .sf-menu .dd_menu {
      position: absolute;
      background: rgba(0, 0, 0, 0.85);
      min-width: 250px;
      display: none;
      padding: 15px 25px;
      text-align: left; }
      #nav .sf-menu .dd_menu li {
        display: block;
        position: relative; }
        #nav .sf-menu .dd_menu li a {
          font-size: 14px;
          line-height: 1.12;
          color: #c1c1c1;
          font-weight: 400;
          text-decoration: none;
          border-left: none;
          padding: 5px 0;
          display: block; }
          #nav .sf-menu .dd_menu li a:hover {
            color: #fff; }

/**     H E A D E R  **/
header.site-header {
  padding: 0;
  position: relative;
  background: #fff; }
  header.site-header > img {
    width: 100%; }
  header.site-header #wrapper {
    position: relative;
    top: 0;
    width: 100%;
    z-index: 200;
    padding: 0; }
    @media (max-width: 992px) {
      header.site-header #wrapper {
        padding-bottom: 0; } }
    @media (max-width: 767px) {
      header.site-header #wrapper {
        padding-top: 0; } }
    header.site-header #wrapper a.btn {
      float: right;
      margin-top: 28px;
      margin-bottom: 20px; }
      @media (max-width: 767px) {
        header.site-header #wrapper a.btn {
          position: fixed;
          top: 0;
          right: -10px;
          z-index: 1020;
          margin-top: 0;
          padding: 10px 12px 9px;
          border-radius: 0; } }
      @media (max-width: 479px) {
        header.site-header #wrapper a.btn {
          padding: 10px 8px 9px; } }
  header.site-header .logo {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 221px; }
    @media (max-width: 1329px) {
      header.site-header .logo {
        top: 6px;
        width: auto; } }
    @media (max-width: 992px) {
      header.site-header .logo {
        width: 140px;
        top: 10px; } }
    @media (max-width: 767px) {
      header.site-header .logo {
        display: block;
        float: none;
        margin-top: 50px;
        padding-bottom: 15px;
        top: 0;
        width: 200px;
        position: relative; } }
    @media (max-width: 479px) {
      header.site-header .logo {
        width: 180px; } }
    header.site-header .logo img {
      width: 100%; }
  header.site-header .phone {
    float: left;
    padding-top: 25px;
    padding-bottom: 15px; }
    @media (max-width: 1329px) {
      header.site-header .phone {
        padding-top: 8px;
        padding-bottom: 8px; } }
    @media (max-width: 767px) {
      header.site-header .phone span {
        display: none; } }
    header.site-header .phone a.tel {
      font-size: 32px;
      line-height: 1.25;
      color: #2b2b2b;
      font-weight: 700;
      display: block; }
      @media (max-width: 992px) {
        header.site-header .phone a.tel {
          font-size: 28px; } }
      @media (max-width: 767px) {
        header.site-header .phone a.tel {
          position: fixed;
          top: 3px;
          right: 160px;
          z-index: 1000;
          color: #fff;
          font-weight: 400; } }
      @media (max-width: 479px) {
        header.site-header .phone a.tel {
          font-size: 18px;
          top: 8px;
          right: 140px; } }

/**    S U B  H E A D E R        **/
/**    B R E A D C R U M B S      B R E A D C R U M B S         **/
/**    S L I D E R         **/
#slider:hover .flex-direction-nav {
  opacity: 1; }

#slider > img {
  width: 100%; }

.flexslider {
  position: relative;
  overflow: hidden;
  background: #2b2b2b; }
  .flexslider ul.slides li {
    display: none; }
    .flexslider ul.slides li img {
      width: 100%; }
      @media (max-width: 767px) {
        .flexslider ul.slides li img {
          max-width: 780px;
          width: 780px;
          position: relative;
          left: 50%;
          transform: translateX(-50%); } }
  .flexslider .flex-control-nav.flex-control-paging {
    text-align: center;
    position: absolute;
    bottom: 30px;
    width: 100%;
    left: 0;
    z-index: 101;
    text-align: center;
    display: none; }
    @media (max-width: 992px) {
      .flexslider .flex-control-nav.flex-control-paging {
        bottom: 10px; } }
    @media (max-width: 767px) {
      .flexslider .flex-control-nav.flex-control-paging {
        display: none; } }
    .flexslider .flex-control-nav.flex-control-paging li {
      display: inline-block;
      text-indent: -9999px;
      padding: 0 8px; }
      .flexslider .flex-control-nav.flex-control-paging li a {
        display: block;
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        opacity: 1;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        border: 2px transparent solid;
        position: relative; }
        .flexslider .flex-control-nav.flex-control-paging li a:hover, .flexslider .flex-control-nav.flex-control-paging li a.flex-active {
          background: #ed6223;
          border-color: #fff;
          opacity: 1;
          width: 12px;
          height: 12px;
          margin-left: -1px;
          margin-top: -1px;
          margin-right: -2px; }
  .flexslider .wrapper-nav {
    position: absolute;
    top: 50%;
    height: 1px;
    width: 100%;
    z-index: 1001; }
    @media (max-width: 1600px) {
      .flexslider .wrapper-nav {
        bottom: 122px; } }
    @media (max-width: 1329px) {
      .flexslider .wrapper-nav {
        bottom: 100px; } }
    @media (max-width: 992px) {
      .flexslider .wrapper-nav {
        bottom: 76px; } }
    @media (max-width: 767px) {
      .flexslider .wrapper-nav {
        display: none; } }
    .flexslider .wrapper-nav .flex-direction-nav {
      padding-left: 15px;
      padding-right: 15px;
      opacity: 0;
      transition: .2s ease-in-out; }
      .flexslider .wrapper-nav .flex-direction-nav li {
        float: left; }
        .flexslider .wrapper-nav .flex-direction-nav li a {
          display: block;
          width: 46px;
          height: 46px;
          opacity: .65;
          font-size: 36px;
          color: #fff;
          line-height: 40px;
          text-align: center;
          text-decoration: none;
          font-family: 'FontAwesome';
          background: #2b2b2b;
          transform: skew(-10deg); }
          .flexslider .wrapper-nav .flex-direction-nav li a:hover {
            opacity: 1; }
      .flexslider .wrapper-nav .flex-direction-nav li + li {
        float: right;
        text-indent: 4px; }
  .flexslider .slogan {
    position: absolute;
    bottom: 45px;
    width: 100%;
    left: 0; }
    @media (max-width: 767px) {
      .flexslider .slogan {
        position: relative;
        bottom: 0;
        width: 100%; } }
    .flexslider .slogan p {
      max-width: 80%; }
      @media (max-width: 767px) {
        .flexslider .slogan p {
          max-width: 100%;
          padding-bottom: 0;
          margin-bottom: 0; } }
      .flexslider .slogan p strong {
        display: inline-block;
        background: #be1e2d;
        font-size: 36px;
        line-height: 1.25;
        color: #fff;
        font-weight: 700;
        letter-spacing: 2px;
        transform: skew(-10deg);
        padding: 24px 36px; }
        @media (max-width: 1329px) {
          .flexslider .slogan p strong {
            font-size: 28px; } }
        @media (max-width: 992px) {
          .flexslider .slogan p strong {
            font-size: 18px; } }
        @media (max-width: 767px) {
          .flexslider .slogan p strong {
            display: block;
            transform: skew(0);
            margin-left: -15px;
            margin-right: -15px;
            text-align: center;
            font-size: 16px; } }
      .flexslider .slogan p span {
        clear: both;
        display: inline-block;
        background: #2b2b2b;
        font-size: 22px;
        line-height: 1.25;
        color: #fff;
        font-weight: 700;
        margin-top: -10px;
        transform: skew(-10deg);
        padding: 10px 30px;
        margin-left: 20px; }
        @media (max-width: 1329px) {
          .flexslider .slogan p span {
            font-size: 18px; } }
        @media (max-width: 992px) {
          .flexslider .slogan p span {
            font-size: 16px; } }
        @media (max-width: 767px) {
          .flexslider .slogan p span {
            margin-top: 0;
            text-align: center;
            display: block; } }

/**     C O N T E N T          **/
section.content {
  padding: 40px 0 50px; }
  @media (max-width: 767px) {
    section.content {
      padding-top: 30px; } }
  section.content h1 {
    margin: 0;
    padding: 0 0 20px 85px;
    font-size: 40px;
    line-height: 1.25;
    color: #be1e2d;
    font-weight: 700;
    position: relative;
    background: url("../img/bg-h1.svg") no-repeat left 20px;
    font-family: "Playfair Display", sans-serif;
    white-space: nowrap; }
    @media (max-width: 1329px) {
      section.content h1 {
        font-size: 24px;
        padding-left: 0;
        background: none; } }
  section.content .wrapper {
    max-width: 560px;
    float: right;
    padding-top: 40px; }
    @media (max-width: 1600px) {
      section.content .wrapper {
        padding-top: 25px; } }
    @media (max-width: 1329px) {
      section.content .wrapper {
        padding-top: 0; } }
    @media (max-width: 992px) {
      section.content .wrapper {
        max-width: 100%;
        padding-top: 15px; } }
    section.content .wrapper p {
      text-align: justify; }
  section.content .comment {
    position: relative;
    overflow: hidden;
    margin-bottom: 25px; }
    section.content .comment img {
      width: 100%; }
    section.content .comment p {
      position: absolute;
      right: -4px;
      bottom: 30px;
      transform: skew(-10deg);
      font-size: 14px;
      line-height: 1.25;
      color: #fff;
      font-weight: 700;
      letter-spacing: 0.8px;
      background: rgba(43, 43, 43, 0.7);
      padding: 12px 24px;
      text-transform: uppercase; }
  section.content a.more {
    font-size: 14px;
    line-height: 1.25;
    color: #9a9a9a;
    font-weight: 400;
    text-transform: uppercase;
    display: inline-block;
    text-decoration: none;
    padding-bottom: 15px;
    padding-top: 10px; }
    section.content a.more:hover {
      color: #000; }
    section.content a.more::after {
      content: "";
      font-family: 'FontAwesome';
      font-size: 20px;
      color: #d1d1d1;
      padding-left: 10px;
      display: inline-block;
      margin-bottom: -4px;
      vertical-align: bottom; }
  section.content p.contact {
    border-top: 1px #d5d5d5 solid;
    padding-top: 25px;
    font-size: 22px;
    line-height: 1.4;
    margin-top: 10px; }
    @media (max-width: 1329px) {
      section.content p.contact {
        font-size: 18px; } }
    @media (max-width: 992px) {
      section.content p.contact {
        font-size: 20px; } }
    @media (max-width: 479px) {
      section.content p.contact {
        font-size: 16px; } }
    section.content p.contact a {
      color: #ae0922; }
      section.content p.contact a.tel {
        color: #2b2b2b; }

hr {
  margin: 0 0 5px;
  padding: 0;
  background: #d7d7d7;
  height: 1px;
  width: 100%;
  border: 0; }

/**     SUB  P A G E    C O N T E N T          **/
section.content.sub-page-content h1 {
  display: block;
  padding-left: 0;
  background: none;
  white-space: normal; }
  section.content.sub-page-content h1 span {
    display: inline-block;
    position: relative;
    background: url("../img/bg-h1.svg") no-repeat left 22px;
    padding-left: 86px; }
    @media (max-width: 1329px) {
      section.content.sub-page-content h1 span {
        background-position: left 8px; } }
    section.content.sub-page-content h1 span::after {
      content: '';
      background: url("../img/bg-h1.svg") no-repeat left 22px;
      transform: scale(-1, 1);
      display: block;
      float: right;
      width: 86px;
      height: 40px; }
    section.content.sub-page-content h1 span.left::after {
      display: none; }
    section.content.sub-page-content h1 span u {
      text-decoration: none;
      text-transform: uppercase; }

section.content.sub-page-content h1 + h3 {
  margin-top: 0;
  padding-top: 0; }

section.content.sub-page-content h3 {
  font-size: 22px;
  line-height: 1.4;
  color: #2b2b2b;
  font-weight: 400;
  font-family: "Playfair Display", sans-serif;
  margin-bottom: 20px;
  padding-bottom: 10px; }
  @media (max-width: 992px) {
    section.content.sub-page-content h3 {
      font-size: 18px; } }
  @media (max-width: 992px) {
    section.content.sub-page-content h3 br {
      display: none; } }

section.content.sub-page-content h2 {
  font-size: 28px;
  line-height: 1.25;
  color: #2b2b2b;
  font-weight: 400;
  margin-bottom: 25px;
  padding-top: 10px; }

section.content.sub-page-content h4 {
  font-size: 24px;
  line-height: 1.25;
  color: #2b2b2b;
  font-weight: 700;
  margin-bottom: 15px;
  padding-top: 10px;
  padding-bottom: 10px; }
  @media (max-width: 992px) {
    section.content.sub-page-content h4 {
      font-size: 20px; } }

section.content.sub-page-content h5 {
  font-size: 18px;
  line-height: 1.25;
  color: #2b2b2b;
  font-weight: 700;
  margin-bottom: 15px;
  padding-top: 10px;
  font-weight: 700; }

section.content.sub-page-content h6 {
  font-size: 20px;
  line-height: 1.25;
  color: #2b2b2b;
  font-weight: 400;
  margin-bottom: 15px;
  padding-top: 10px; }
  @media (max-width: 992px) {
    section.content.sub-page-content h6 {
      font-size: 16px; } }
  section.content.sub-page-content h6 a {
    color: #ae0922; }
    section.content.sub-page-content h6 a.tel {
      color: #2b2b2b;
      font-weight: 700; }

section.content.sub-page-content .inner {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto; }

section.content.sub-page-content hr {
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 20px; }

.content-text > br:first-child {
  display: none; }

.content-text p {
  text-indent: 30px; }
  .content-text p.non-indent {
    text-indent: 0px; }

.content-text ul {
  padding-top: 10px;
  padding-bottom: 20px; }
  .content-text ul li {
    font-size: 16px;
    line-height: 1.4;
    color: #2b2b2b;
    font-weight: 400;
    padding-left: 20px;
    padding-bottom: 10px;
    background: url("../img/list.svg") no-repeat left 6px; }

/* S I D E B A R   */
.sidebar ul {
  margin: 0 0 35px 0;
  padding: 0;
  border: 1px #d5d5d5 solid; }
  .sidebar ul li a {
    display: block;
    text-decoration: none;
    font-size: 16px;
    line-height: 1.25;
    color: #2b2b2b;
    font-weight: 400;
    border-left: 5px #f6f6f6 solid;
    padding-left: 18px;
    padding-top: 8px;
    padding-bottom: 8px; }
    .sidebar ul li a:hover {
      color: #5d5d5d;
      border-left-color: #2b2b2b; }
    .sidebar ul li a.current-menu-item {
      color: #202020;
      font-weight: 700; }
    @media (max-width: 992px) {
      .sidebar ul li a {
        font-size: 12px;
        padding-left: 10px; } }
  .sidebar ul li + li {
    border-top: 1px #d5d5d5 solid; }

.wrap-form {
  background: #eeeeee;
  padding: 10px 24px 40px 24px;
  margin-bottom: 20px; }
  @media (max-width: 992px) {
    .wrap-form {
      padding: 8px 15px 25px; } }
  .wrap-form h4 {
    font-size: 24px;
    line-height: 1.25;
    color: #292c33;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    margin-bottom: 5px;
    padding-bottom: 0; }

/* B A N N E R S  */
.banners {
  background: #2b2b2b;
  padding: 35px 0 25px; }
  @media (max-width: 992px) {
    .banners {
      padding: 25px 0 20px; } }
  @media (max-width: 767px) {
    .banners {
      background: #000;
      padding: 20px 0 15px; } }
  .banners h1 {
    display: block;
    padding-left: 0;
    background: none;
    color: #fff;
    font-family: "Playfair Display", sans-serif;
    font-size: 40px;
    line-height: 1.25;
    color: #fff;
    font-weight: 600;
    text-align: center;
    padding-bottom: 25px; }
    @media (max-width: 992px) {
      .banners h1 {
        font-size: 24px; } }
    @media (max-width: 767px) {
      .banners h1 {
        font-size: 22px; } }
    .banners h1 span {
      display: inline-block;
      position: relative;
      background: url("../img/bg-h1-white.svg") no-repeat left 20px;
      padding-left: 100px; }
      @media (max-width: 767px) {
        .banners h1 span {
          background: none;
          padding-left: 0; } }
      .banners h1 span::after {
        content: '';
        background: url("../img/bg-h1-white.svg") no-repeat left 20px;
        transform: scale(-1, 1);
        display: block;
        float: right;
        width: 86px;
        height: 40px; }
        @media (max-width: 767px) {
          .banners h1 span::after {
            display: none; } }
  .banners h3 {
    padding: 0 0 35px 0;
    margin: 0;
    float: left;
    font-size: 24px;
    line-height: 1.25;
    color: #fff;
    font-weight: 900;
    max-width: 940px;
    font-family: "Playfair Display", sans-serif; }
    @media (max-width: 1329px) {
      .banners h3 {
        font-size: 20px;
        max-width: 780px; } }
    @media (max-width: 992px) {
      .banners h3 {
        font-size: 15px;
        max-width: 520px; } }
    @media (max-width: 767px) {
      .banners h3 {
        font-size: 16px;
        max-width: none;
        text-align: center; } }
  .banners a.btn {
    float: right;
    margin-top: 10px; }
    @media (max-width: 992px) {
      .banners a.btn {
        margin-top: 2px; } }
    @media (max-width: 767px) {
      .banners a.btn {
        display: none; } }
    .banners a.btn:hover {
      background: #000; }
  .banners a.banner {
    display: block;
    background: #fff;
    margin-bottom: 25px;
    text-decoration: none;
    color: #2b2b2b;
    overflow: hidden;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease; }
    .banners a.banner .inner {
      padding: 20px 18px;
      float: left;
      max-width: 50%;
      position: relative; }
      @media (max-width: 1600px) {
        .banners a.banner .inner {
          padding: 18px 18px 0 18px; } }
      @media (max-width: 1329px) {
        .banners a.banner .inner {
          padding: 12px 16px 0 16px; } }
      @media (max-width: 479px) {
        .banners a.banner .inner {
          max-width: 100%; } }
      .banners a.banner .inner h4 {
        margin: 0;
        padding: 0 0 6px 0;
        font-size: 24px;
        line-height: 1.25;
        color: #2b2b2b;
        font-weight: 700; }
        @media (max-width: 1600px) {
          .banners a.banner .inner h4 {
            font-size: 20px; } }
        @media (max-width: 1329px) {
          .banners a.banner .inner h4 {
            font-size: 16px; } }
        @media (max-width: 992px) {
          .banners a.banner .inner h4 {
            font-size: 24px; } }
        @media (max-width: 767px) {
          .banners a.banner .inner h4 {
            font-size: 18px; } }
      .banners a.banner .inner p {
        font-size: 16px;
        line-height: 1.25;
        color: #2b2b2b;
        font-weight: 400; }
        @media (max-width: 1600px) {
          .banners a.banner .inner p {
            font-size: 15px; } }
        @media (max-width: 1329px) {
          .banners a.banner .inner p {
            font-size: 14px;
            line-height: 1.1; } }
        @media (max-width: 992px) {
          .banners a.banner .inner p {
            font-size: 16px; } }
        @media (max-width: 767px) {
          .banners a.banner .inner p {
            font-size: 14px; } }
    .banners a.banner img {
      float: right;
      max-width: 50%; }
      @media (max-width: 479px) {
        .banners a.banner img {
          float: none;
          max-width: 100%;
          width: 100%; } }
    .banners a.banner span {
      color: #fff;
      text-transform: uppercase;
      font-size: 14px; }
      @media (max-width: 1329px) {
        .banners a.banner span {
          font-size: 12px; } }
      @media (max-width: 767px) {
        .banners a.banner span {
          display: none; } }
      .banners a.banner span::after {
        content: '';
        font-family: 'FontAwesome';
        color: #fff;
        font-size: 12px;
        padding-left: 2px; }
    .banners a.banner em {
      font-style: normal;
      font-size: 94px;
      line-height: 1;
      color: #f0f0f0;
      font-weight: 900;
      position: absolute;
      right: 50%;
      bottom: 15px;
      font-family: "Lato", sans-serif;
      -webkit-transition: all 0.15s ease;
      -moz-transition: all 0.15s ease;
      -ms-transition: all 0.15s ease;
      -o-transition: all 0.15s ease;
      transition: all 0.15s ease; }
      @media (max-width: 1600px) {
        .banners a.banner em {
          font-size: 60px;
          bottom: 18px; } }
      @media (max-width: 1329px) {
        .banners a.banner em {
          font-size: 60px;
          bottom: 18px; } }
      @media (max-width: 767px) {
        .banners a.banner em {
          display: none; } }
    .banners a.banner:hover {
      background: #be1e2d; }
      .banners a.banner:hover h4, .banners a.banner:hover p {
        color: #fff; }
      .banners a.banner:hover span {
        color: #c8c8c8; }
        .banners a.banner:hover span::after {
          color: #c8c8c8; }
      .banners a.banner:hover em {
        color: #f0f0f0;
        opacity: 0.1; }

/* C O N T A C T S   */
.contacts #button_submit, .contacts #fields .submit {
  max-width: 180px;
  float: right; }
  @media (max-width: 992px) {
    .contacts #button_submit, .contacts #fields .submit {
      float: left; } }
  @media (max-width: 767px) {
    .contacts #button_submit, .contacts #fields .submit {
      float: none; } }

.wrapp-form {
  padding: 8px;
  background: #eee; }
  .wrapp-form .inner-form {
    border: 1px #fff solid;
    background: #eee;
    padding: 28px; }
    @media (max-width: 1329px) {
      .wrapp-form .inner-form {
        padding: 16px; } }
    .wrapp-form .inner-form h3 {
      margin: 0;
      padding: 0 0 10px 0;
      font-size: 26px;
      line-height: 1.25;
      color: #292c33;
      font-weight: 400; }
      @media (max-width: 1329px) {
        .wrapp-form .inner-form h3 {
          font-size: 22px; } }

/* G A L L E R Y */
a.gallery {
  display: block;
  border: 1px #d0d0d0 solid;
  padding: 10px;
  background: #fff;
  margin-bottom: 25px;
  margin-top: 10px;
  position: relative; }
  a.gallery::before {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    background: rgba(2, 99, 175, 0.2); }
  a.gallery::after {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    font-family: 'FontAwesome';
    text-align: center;
    color: #fff;
    font-size: 30px;
    transform: translate(-50%, -50%); }
  a.gallery:hover::before, a.gallery:hover::after {
    opacity: 1; }
  a.gallery img {
    width: 100%; }

/**     F O O T E R        F O O T E R         F O O T E R         **/
footer.site-footer {
  padding: 70px 0 55px;
  background: #f5f5f5; }
  @media (max-width: 992px) {
    footer.site-footer {
      padding: 28px 0 20px; } }
  @media (max-width: 479px) {
    footer.site-footer div[class*="col-xs"] {
      float: none;
      width: 100%;
      text-align: center; } }
  footer.site-footer h5 {
    margin: 0;
    padding: 0 0 10px 0;
    font-size: 16px;
    line-height: 1.2;
    color: #2b2b2b;
    font-weight: 700;
    text-transform: uppercase; }
  footer.site-footer p {
    font-size: 16px;
    line-height: 1.38;
    color: #2b2b2b;
    font-weight: 400; }
    @media (max-width: 992px) {
      footer.site-footer p {
        font-size: 13px; } }
  footer.site-footer a.tel {
    font-size: 30px;
    line-height: 1.38;
    color: #2b2b2b;
    font-weight: 700; }
    @media (max-width: 992px) {
      footer.site-footer a.tel {
        font-size: 24px; } }
    @media (max-width: 767px) {
      footer.site-footer a.tel {
        font-size: 20px; } }
  footer.site-footer .logo {
    padding-top: 10px; }
    @media (max-width: 767px) {
      footer.site-footer .logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 180px;
        margin-bottom: 25px; } }

/*-------------------    Copyright  --------------------*/
.copyright {
  padding: 15px 0;
  background: #d7d7d7; }
  .copyright p {
    font-size: 14px;
    line-height: 1.2;
    color: #595959;
    font-weight: 400;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 5px;
    padding-bottom: 10px; }
    @media (max-width: 992px) {
      .copyright p {
        font-size: 12px; } }
    @media (max-width: 767px) {
      .copyright p {
        float: none !important;
        width: 100%;
        text-align: center; } }
    .copyright p a {
      color: #595959;
      text-decoration: none; }
      .copyright p a:hover {
        color: #000; }

/**     F O R M    F O R M    F O R M    F O R M    F O R M    F O R M     **/
#ajax-contact-form {
  text-align: left; }
  #ajax-contact-form p {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0; }
    #ajax-contact-form p span {
      display: block;
      line-height: 1.2;
      font-size: 14px;
      line-height: 1.5;
      color: #3a3a3a;
      font-weight: 700;
      font-family: "Lato", sans-serif;
      padding-top: 4px; }
      @media (max-width: 992px) {
        #ajax-contact-form p span {
          font-size: 12px; } }
  #ajax-contact-form label {
    margin-bottom: 0; }

#ajax-contact-form textarea,
#ajax-contact-form select,
#ajax-contact-form input {
  padding: 4px 12px 6px;
  background: #fff;
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5em;
  color: #282828;
  font-weight: 300;
  margin: 4px 0 12px;
  border: solid 1px #c7cbce; }

#ajax-contact-form select {
  font-size: 14px;
  padding-left: 8px;
  height: 36px;
  color: #93979b; }

#ajax-contact-form textarea:active,
#ajax-contact-form input:active,
#ajax-contact-form select:active,
#ajax-contact-form select:focus,
#ajax-contact-form textarea:focus,
#ajax-contact-form input:focus {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #a6a6a6;
  background: #fff;
  color: #000;
  outline: none; }

#ajax-contact-form textarea {
  height: 70px; }

#ajax-contact-form *::-webkit-input-placeholder {
  color: #93979b;
  font-family: "Lato", sans-serif;
  font-size: 14px; }

#ajax-contact-form *::-moz-placeholder {
  color: #93979b;
  font-family: "Lato", sans-serif;
  font-size: 14px; }

/* Firefox 19+ */
#ajax-contact-form *:-moz-placeholder {
  color: #93979b;
  font-family: "Lato", sans-serif;
  font-size: 14px; }

/* Firefox 18- */
#ajax-contact-form *:-ms-input-placeholder {
  color: #93979b;
  font-family: "Lato", sans-serif;
  font-size: 14px; }

#ajax-contact-form *:focus::-webkit-input-placeholder {
  color: #93979b;
  font-family: "Lato", sans-serif;
  font-size: 14px; }

#ajax-contact-form *:focus::-moz-placeholder {
  color: #93979b;
  font-family: "Lato", sans-serif;
  font-size: 14px; }

/* Firefox 19+ */
#ajax-contact-form *:focus:-moz-placeholder {
  color: #93979b;
  font-family: "Lato", sans-serif;
  font-size: 14px; }

/* Firefox 18- */
#ajax-contact-form *:focus:-ms-input-placeholder {
  color: #93979b;
  font-family: "Lato", sans-serif;
  font-size: 14px; }

#ajax-contact-form #zip {
  float: right;
  width: 28%; }

#button_submit,
#fields .submit {
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  font-size: 18px;
  line-height: 36px;
  color: #fff;
  font-weight: 300;
  margin: 0;
  text-decoration: none;
  background: none;
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 6px 10px;
  border-radius: 0;
  text-align: center;
  background: #292c33;
  width: 100%;
  border: none;
  text-transform: uppercase;
  border: none !important; }
  @media (max-width: 1329px) {
    #button_submit,
    #fields .submit {
      font-size: 14px; } }
  @media (max-width: 992px) {
    #button_submit,
    #fields .submit {
      max-width: 240px;
      margin-left: auto;
      margin-right: auto;
      float: none; } }
  @media (max-width: 767px) {
    #button_submit,
    #fields .submit {
      line-height: 20px; } }
  @media (max-width: 479px) {
    #button_submit,
    #fields .submit {
      font-size: 11px; } }
  #button_submit:hover,
  #fields .submit:hover {
    color: #fff;
    background: #2b2b2b; }

.notification_ok {
  padding-bottom: 15px;
  color: #000;
  line-height: 1.5em;
  font-size: 18px;
  text-align: left;
  padding-top: 15px; }

.notification_error {
  color: #e54423;
  clear: both;
  text-align: left !important;
  font-size: 12px;
  line-height: 1.5em;
  font-weight: 700; }

#note {
  clear: both; }

#rc-imgelect, .g-recaptcha {
  width: 250px;
  transform: scale(0.82);
  transform-origin: 0 0;
  float: left; }
  @media (max-width: 1600px) {
    #rc-imgelect, .g-recaptcha {
      transform: scale(0.74);
      transform-origin: 0 0; } }
  @media (max-width: 1329px) {
    #rc-imgelect, .g-recaptcha {
      transform: scale(0.6);
      transform-origin: 0 0; } }
  @media (max-width: 992px) {
    #rc-imgelect, .g-recaptcha {
      transform: scale(0.8);
      transform-origin: 0 0; } }
  @media (max-width: 479px) {
    #rc-imgelect, .g-recaptcha {
      float: none; } }

img {
  height: auto;
  max-width: 100%; }

.clearfix {
  clear: both; }

#ajax-contact-form label.error {
  color: #f00;
  font-size: 12px;
  margin-top: -8px;
  display: inline-block;
  font-weight: 400;
  clear: both; }

@media (max-width: 992px) {
  .mobile-none {
    display: none; } }

div#button_submit {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px; }

/**     
mobile menu   mobile menu    mobile menu   mobile menu      mobile menu        **/
.slicknav_menu {
  font-size: 16px;
  box-sizing: border-box;
  background: #2b2b2b;
  display: none;
  z-index: 200;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0; }
  @media (max-width: 767px) {
    .slicknav_menu {
      display: block; } }
  .slicknav_menu .phone-mobile {
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    font-weight: 300;
    float: left;
    padding-left: 10px;
    padding-top: 6px; }
    .slicknav_menu .phone-mobile a {
      font-size: 16px;
      line-height: 28px;
      color: #fff;
      font-weight: 300; }
    @media (max-width: 479px) {
      .slicknav_menu .phone-mobile span {
        display: none; } }
  .slicknav_menu * {
    box-sizing: border-box; }
  .slicknav_menu .slicknav_menutxt {
    color: #fff;
    font-weight: bold;
    text-shadow: 0 1px 3px #000; }
  .slicknav_menu .slicknav_icon-bar {
    background-color: #f5841f; }

.slicknav_nav {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 0.875em;
  list-style: none;
  overflow: hidden;
  background: #000;
  z-index: 9999; }
  .slicknav_nav .slicknav_row:click, .slicknav_nav .slicknav_row:active, .slicknav_nav .slicknav_row:focus {
    color: #be1e2d;
    outline: none; }
  .slicknav_nav a {
    font-size: 16px;
    line-height: 40px;
    color: #fff;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    border-bottom: 1px rgba(255, 255, 255, 0.2) dashed;
    padding-bottom: 0;
    text-transform: uppercase;
    padding-left: 20px;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease; }
    .slicknav_nav a:hover {
      color: #be1e2d; }
    @media (max-width: 767px) {
      .slicknav_nav a + i {
        display: none; } }
  .slicknav_nav a.btn,
  .slicknav_nav a.btn:active,
  .slicknav_nav a.btn:focus {
    background: none;
    border: 0;
    border-top: 1px rgba(255, 255, 255, 0.2) dashed;
    border-bottom: 1px rgba(255, 255, 255, 0.2) dashed;
    text-transform: capitalize;
    border-radius: 0;
    padding-top: 0; }
  .slicknav_nav .current-menu-item a {
    color: #fff; }
  .slicknav_nav .slicknav_txtnode {
    margin-left: 15px; }
  .slicknav_nav .slicknav_item a {
    padding: 0;
    margin: 0; }
  .slicknav_nav .slicknav_parent-link a {
    padding: 0;
    margin: 0; }

.slicknav_brand {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  padding: 7px 12px;
  height: 44px; }

.slicknav_btn {
  display: block;
  margin: 0;
  padding: 0;
  text-decoration: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: url("../img/mobile-button.png") 50% 2px no-repeat;
  background-size: 39px auto;
  float: left;
  margin: 0;
  padding: 0;
  top: 1px;
  left: 10px;
  position: relative;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease; }
  .slicknav_btn span {
    display: none; }
  .slicknav_btn.slicknav_open {
    background-position: 50% -34px; }

.slicknav_menu {
  *zoom: 1; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left; }
  .slicknav_menu .slicknav_icon {
    float: left;
    width: 1.125em;
    height: 0.875em;
    margin: 0.188em 0 0 0.438em; }
    .slicknav_menu .slicknav_icon:before {
      background: transparent;
      width: 1.125em;
      height: 0.875em;
      display: block;
      content: "";
      position: absolute; }
  .slicknav_menu .slicknav_no-text {
    margin: 0; }
  .slicknav_menu .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: 0.125em;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); }
  .slicknav_menu:before {
    content: " ";
    display: table; }
  .slicknav_menu:after {
    content: " ";
    display: table;
    clear: both; }

.slicknav_nav {
  clear: both; }
  .slicknav_nav ul {
    list-style: none;
    overflow: hidden;
    padding: 10px 0 10px 0;
    margin: 0; }
    .slicknav_nav ul li a {
      text-transform: capitalize;
      line-height: 24px;
      color: #fff;
      border: none;
      font-size: 15px;
      font-weight: 400;
      padding-left: 30px;
      display: block; }
      .slicknav_nav ul li a:hover {
        color: #be1e2d; }
  .slicknav_nav .slicknav_arrow {
    font-size: 0.8em;
    color: #be1e2d;
    font-family: 'FontAwesome';
    float: right;
    margin-right: 20px; }
  .slicknav_nav .slicknav_item {
    cursor: pointer; }
    .slicknav_nav .slicknav_item a {
      display: inline;
      border-bottom: none; }
      .slicknav_nav .slicknav_item a:click, .slicknav_nav .slicknav_item a:active, .slicknav_nav .slicknav_item a:focus {
        color: #be1e2d;
        outline: none; }
  .slicknav_nav .slicknav_row {
    display: block; }
    .slicknav_nav .slicknav_row:click, .slicknav_nav .slicknav_row:active, .slicknav_nav .slicknav_row:focus {
      color: #fff;
      outline: none; }
  .slicknav_nav a {
    display: block; }
  .slicknav_nav .slicknav_parent-link a {
    display: inline; }

/**     O T H E R       **/
a {
  color: #00215c;
  text-decoration: underline; }

a:focus {
  color: #00215c;
  text-decoration: none; }

a:hover {
  text-decoration: none;
  color: #000; }

.center {
  text-align: center; }

.scrolloff {
  pointer-events: none; }

.map {
  position: relative;
  padding-bottom: 50%;
  padding-top: 30px;
  height: 0;
  background: #ccc; }
  .map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0; }

ul.list {
  padding: 0 0 20px 0;
  margin: 16px 0 0 12px;
  overflow: hidden; }
  ul.list li {
    padding-left: 30px;
    position: relative;
    padding-bottom: 8px;
    font-size: 16px;
    line-height: 1.4;
    color: #292b2e;
    font-weight: 400;
    font-family: "Lato", sans-serif; }
    ul.list li ul {
      padding-top: 15px; }
    ul.list li::after {
      width: 9px;
      height: 9px;
      position: absolute;
      left: 2px;
      top: -1px;
      border-radius: 50%;
      content: '';
      display: block;
      font-size: 18px;
      font-family: "FontAwesome";
      color: #ce0316; }

.right {
  float: right; }
  @media (max-width: 767px) {
    .right {
      float: none; } }

.paddcontent {
  padding-bottom: 40px; }
  @media (max-width: 767px) {
    .paddcontent {
      padding-bottom: 10px; } }

/* Buttons */
.btn:active, .btn:focus,
.btn {
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  font-size: 14px;
  line-height: 1.4em;
  color: #fff;
  font-weight: 700;
  margin: 0;
  text-decoration: none;
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 16px 36px;
  border-radius: 0;
  text-align: center;
  background-color: #ce0316;
  text-transform: uppercase;
  text-shadow: none;
  transform: skew(-10deg);
  letter-spacing: 0.8px; }
  @media (max-width: 1600px) {
    .btn:active, .btn:focus,
    .btn {
      padding: 16px 30px; } }
  @media (max-width: 1329px) {
    .btn:active, .btn:focus,
    .btn {
      padding: 6px 12px;
      font-size: 14px; } }
  @media (max-width: 767px) {
    .btn:active, .btn:focus,
    .btn {
      padding: 12px 12px;
      transform: skew(0); } }
  .btn:active span, .btn:focus span,
  .btn span {
    transform: skew(10deg);
    display: block; }
  .btn:active:hover, .btn:focus:hover,
  .btn:hover {
    color: #fff;
    background: #2b2b2b; }

/* G A L L E R Y  */
/* House plans page.
------------------------------------------------------------ */
.content-text .filter {
  text-align: center;
  font-size: 0;
  padding-bottom: 0;
  margin-bottom: 30px; }
  .content-text .filter li {
    display: inline-block;
    border-top: 1px #cccccc solid;
    border-bottom: 1px #cccccc solid;
    background: none !important;
    margin-bottom: 0;
    padding: 0; }
    .content-text .filter li::before, .content-text .filter li::after {
      display: none; }
    .content-text .filter li span {
      display: block;
      padding: 0 4px;
      font-size: 14px;
      color: #707070;
      line-height: 40px;
      text-transform: uppercase;
      cursor: pointer;
      border-bottom: 3px transparent solid; }
      @media (max-width: 1329px) {
        .content-text .filter li span {
          font-size: 13px;
          padding: 0 10px; } }
      @media (max-width: 992px) {
        .content-text .filter li span {
          font-size: 12px; } }
      .content-text .filter li span:hover {
        border-color: #be1e2d; }
      .content-text .filter li span.active {
        font-weight: 700; }
  .content-text .filter li + li {
    padding-left: 12px; }

.blocks .inner {
  border-bottom: 1px solid #d6d6d6;
  position: relative;
  display: block;
  margin-bottom: 30px; }
  .blocks .inner a {
    display: block;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.4em;
    color: #2b2b2b;
    font-weight: 400; }
    .blocks .inner a::after {
      content: url("../img/zoom.svg");
      position: absolute;
      z-index: 1;
      transition: .3s;
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    .blocks .inner a p {
      padding-bottom: 10px;
      padding-top: 10px;
      margin-bottom: 0;
      text-indent: 0;
      padding-left: 0px;
      transition: .3s; }
    .blocks .inner a:hover {
      background: #f3f3f3; }
      .blocks .inner a:hover p {
        padding-left: 15px; }
      .blocks .inner a:hover::after {
        opacity: 1; }

.smallpadd {
  padding-bottom: 10px; }

.red {
  color: #ba0003; }

a[href*="tel"] {
  text-decoration: none;
  cursor: default; }

::selection {
  background: #ededed !important;
  color: #000000; }

::-moz-selection {
  background: #ededed !important;
  color: #000000; }
