@import "_variables";

/* Fonts */
/*
font-family: $Playfair;
font-family: $Lato;
*/

/* Media Screens */
.container {
 width: 970px;
 margin: 0 auto;
	body & {
		@include break(large) {
			width: 1270px;
		}
	}
  @include break(desktop) {
		width: 1170px;
	}
	@include break(neutral) {
		width: 721px;
	}
	@include break(mobile) {
		width: 100%;
	}
	@include break(x-small) {
		width: 100%;
		min-width: 320px;
	}
}
html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
	background: #fff;
}
body {
	@include fz(18px, 1.5em, #292b2e, 400);
	font-family: $Lato;
	@include break(medium) {
		font-size: 15px;
	}

	@include break(x-small) {
		min-width: 320px;
		font-size: 14px;
	}
}
.site-container {
	max-width: 3000px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	position: relative;
	background: #fff ;
	background-size: 100%;
	@include break(mobile) {

	}
}

.front-end {
	background: #ccc;
	border-radius:5px;
	@include break(x-small) {
		background: #fff;
	}

}


/**   nav menu  **/

#nav {
	background: #2b2b2b;
	@include break(neutral) {		}
	@include break(medium) {	}
	@include break(mobile) {display: none;}
	@include clearfix();

	.sf-menu {
		position: relative;
		font-size: 0;
		line-height: 0;
		border-radius: 0 0 4px 4px;		
		white-space: nowrap;	
		text-align: center;	
		@include break(custom) {
		}
		@include break(medium) {
		}
		@include break(neutral) {
		}

		& > li {
			margin: 0 10px;
			padding: 0;
			position: relative;
			display: inline-block;
			font-size: 0;
			line-height: 0;

			& > a {
				@include fz(16px, 1.4, #d8d8d8, 700);
				text-decoration: none;
				display: inline-block;
				margin: 0 20px ;
				padding: 15px 0;
				transition: .2s ease-in-out;
				text-transform: uppercase;								
				letter-spacing: 1px;
				border-bottom: 3px transparent solid;
				letter-spacing: 0.9px;
				@include break(custom) {
				}
				@include break(medium) {
					font-size: 14px;
				}
				@include break(neutral) {
					font-size: 14px;
					font-weight: 400;
				}
			}

			&.sfHover,
			&:hover {
				& > a {
					color: #fff;	
					border-bottom-color: #be1e2d
				}
			}
			& > a.current-menu-item {
				color: #fff;	
				&:hover {
				}
			}
	
			&.has-menu > a {
				&::before {
					content:'';
					display: block;
					float: right;
					font-family: "FontAwesome";
				  font-size: 16px;
				  color: #d7d7d7;
				  margin-left: 10px;
				  font-weight: 400;
				  margin-right: -10px;
					@include break(medium) {
						display: none;						
					}
				}
			}
	
		}
		& > li + li {

			&:before {
				width: 0;
				height: 0;
				border: 4px solid transparent;
				border-bottom: 7px solid #bfbfbf;
				position: relative;
				top: 18px;
				opacity: 0.4;
				content: '';
				left:-10px;
			}
			&:after {
				content: '';
				position: absolute;
				left: -10px; 
				top: 25px;
				width: 0;
				height: 0;
				border: 4px solid transparent;
				border-top: 7px solid #bfbfbf;
				opacity: 0.4;
			} 

		}

		.dd_menu {
			position: absolute;
			background: rgba(0, 0, 0, .85);
			min-width: 250px;
			display: none;
			padding: 15px 25px;
			text-align: left;
	
			li {
				display: block;				
				position: relative;
				a {
					@include fz(14px, 1.12, #c1c1c1, 400);								
					text-decoration: none;
					border-left: none;
					padding: 5px 0;
					display: block;
					&:hover {
						color: #fff;
					}
				}
			}
			li + li {
				//border-top: 1px #eaeaea solid;
			}
		}
	}
}




/**     H E A D E R  **/
header.site-header {
	padding: 0;
	position: relative;
	background: #fff;

	@include break(mobile){
	}
	& > img {
		width: 100%;		
	}

	#wrapper {
		position: relative;			
		top: 0;
		width: 100%;
		z-index: 200;
		padding: 0;
		@include break(neutral) {
			padding-bottom: 0;
		}
		@include break(mobile) {
			padding-top: 0;
		}

		a.btn {
  		 float: right;
  		 margin-top: 28px;
  		 margin-bottom: 20px;
			@include break(mobile) {
				position: fixed;
				top: 0;
				right:-10px;
				z-index: 1020;
				margin-top: 0;
				padding: 10px 12px 9px;
				border-radius: 0;
			}
			@include break(x-small) {
				padding: 10px 8px 9px;
			}
		}
	}

	.logo {
	  position: absolute;
	  top: 15px;
	  left:50%;
	  transform: translateX(-50%);
	  width: 221px;
	  @include break(medium) {
	  	top: 6px;
	  	width: auto;
	  }
	  @include break(neutral) {
	  	width: 140px;
	  	top: 10px;
	  }
	  @include break(mobile) {
	  	display: block;
	  	float: none;
	  	margin-top: 50px;
	  	padding-bottom: 15px;
	  	top: 0;
	  	width: 200px;
	  	position: relative;
	  }
	  @include break(x-small) {
	  	width: 180px;
	  }
	  img {
	  	width: 100%;
	  }

	}
	.phone {
		float: left;
		padding-top: 25px;
		padding-bottom: 15px;
		@include break(medium) {
			padding-top: 8px;
			padding-bottom: 8px;
		}
		@include break(mobile) {
			span {
				display: none;
			}
		}
		@include break(x-small) {		}
		a.tel {
			@include fz(32px, 1.25, #2b2b2b, 700);						
			display: block;
			@include break(neutral) {
				font-size: 28px;
			}	
			@include break(mobile) {
				position: fixed;
				top: 3px;
				right:160px;
				z-index: 1000;
				color: #fff;
				font-weight: 400;
			}	
			@include break(x-small) {
				font-size: 18px;
				top: 8px;
				right: 140px;
			}	
		}
	}
}



/**    S U B  H E A D E R        **/

.sub-header {}


/**    B R E A D C R U M B S      B R E A D C R U M B S         **/

.breadcrumbs {}


/**    S L I D E R         **/
#slider {
	&:hover {
		.flex-direction-nav {
			opacity:1;
		}
	}
	& > img {
		width: 100%;
	}
}


.flexslider {
	position: relative;
	overflow: hidden;
	background: #2b2b2b;

	ul.slides {
		li {
			display: none;			
			img {
				width: 100%;
			  @include break(mobile) {
			  	max-width: 780px;
			  	width: 780px;
			  	position: relative;
			  	left:50%;
			  	transform: translateX(-50%);
				}		
			}
		}	
	}

	.flex-control-nav.flex-control-paging {
		text-align: center;		
		position: absolute;
		bottom: 30px;
		width: 100%;
		left:0;
		z-index: 101;
		text-align: center;		
		display: none;
		
	  @include break(neutral) {
	  	bottom: 10px;
		}
	  @include break(mobile) {
	  	display: none;	  	
		}		

		li {
			display: inline-block;
			text-indent: -9999px;
			padding: 0 8px;
			a {
				display: block;
				background-color: #fff;
				box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);				
  			opacity: 1;
  			width: 9px;
  			height: 9px;
 				border-radius: 50%;
 				border: 2px transparent solid;
 				position: relative;
 				
  			&:hover,
  			&.flex-active {
  				background: #ed6223;
  				border-color:#fff;
  				opacity: 1;
	  			width: 12px;
	  			height: 12px;
	  			margin-left: -1px;
	  			margin-top: -1px;
	  			margin-right: -2px;
  			}

			}
		}
	}

	.wrapper-nav {
		position: absolute;
		top:50%;
		height: 1px;
		width: 100%;
		z-index: 1001; 
		@include break(custom) {
			bottom:122px;
		}
		@include break(medium) {
			bottom: 100px; 
		}
		@include break(neutral) {
			bottom: 76px; 
		}
		@include break(mobile) {
			display: none;			
		}

		.flex-direction-nav {
			padding-left: 15px;
			padding-right: 15px;
			opacity:0;
			transition: .2s ease-in-out;			
			li {
				float: left;
				a {
					display: block;
					width: 46px;
					height: 46px;
					opacity: .65;
					font-size: 36px;
					color: #fff;
					line-height: 40px;
					text-align: center;
					text-decoration: none;					
			  	    font-family: 'FontAwesome';
			  	    background: #2b2b2b;
					transform:  skew(-10deg);
					&:hover {
						opacity: 1;
					} 
				}
			}
			li + li {
				float: right;
				text-indent: 4px;
			}
		}
	}

	.slogan {
		position: absolute;
		bottom: 45px;
		width: 100%;
		left:0;
		@include break(mobile) {
			position: relative;
			bottom:0;
			width: 100%;
		}

		p {
			max-width: 80%;
			@include break(mobile) {
			  max-width: 100%;
			  padding-bottom: 0;
			  margin-bottom: 0;
			}	

			strong {
				display: inline-block;
				background: #be1e2d;
				@include fz(36px, 1.25, #fff, 700);			
				letter-spacing: 2px;
				transform:  skew(-10deg);
				padding: 24px 36px;
				@include break(medium) {
					font-size: 28px;
				}	
				@include break(neutral) {
					font-size: 18px;
				}	
				@include break(mobile) {
					display: block;
					transform:  skew(0);
					margin-left: -15px;
  				    margin-right: -15px;
					text-align: center;
					font-size: 16px;
				}	
			}

			span {
				clear: both;
				display: inline-block;
				background: #2b2b2b;
				@include fz(22px, 1.25, #fff, 700);			
				margin-top: -10px;
				transform:  skew(-10deg);
				padding: 10px 30px;
				margin-left: 20px;
				@include break(medium) {
					font-size: 18px;
				}	
				@include break(neutral) {
					font-size: 16px;
				}	
				@include break(mobile) {
					margin-top: 0;
					text-align: center;
					display: block;
				}	
			}
		}
	}
}




/**     C O N T E N T          **/
section.content {
	padding: 40px 0 50px;
	@include break(mobile) {
		padding-top: 30px;
	}	
	h1 {
		margin: 0;
		padding: 0 0 20px 85px;
		@include fz(40px, 1.25, #be1e2d, 700);			
		position: relative;
		background: url("../img/bg-h1.svg") no-repeat left 20px;
		font-family: $Playfair;
		white-space: nowrap;
		@include break(medium) {
			font-size: 24px;
			padding-left: 0;
			background: none;
		}	
	}

	.wrapper {
		max-width: 560px;
		float: right;
		padding-top: 40px;
		@include break(custom) {
			padding-top: 25px;
		}	
		@include break(medium) {
			padding-top: 0;
		}	
		@include break(neutral) {
			max-width: 100%;
			padding-top: 15px;
		}	

		p {
			text-align: justify;
		}
	}
	.comment {
		position: relative;
		overflow: hidden;
		margin-bottom: 25px;
		
		img {
			width: 100%;
		}

		p {
			position: absolute;
			right:-4px;
			bottom: 30px;
			transform:  skew(-10deg);
			@include fz(14px, 1.25, #fff, 700);			
			letter-spacing: 0.8px;
			background: rgba(43, 43, 43, 0.7);
			padding: 12px 24px;
			text-transform: uppercase;
		}
	}
	a.more {
		@include fz(14px, 1.25, #9a9a9a, 400);			
		text-transform: uppercase;
		display: inline-block;
		text-decoration: none;
		padding-bottom: 15px;
		padding-top: 10px;
		&:hover {
			color: #000;
		}
		&::after  {
			content: "";
			font-family: 'FontAwesome';
			font-size: 20px;
  			color: #d1d1d1;
  			padding-left: 10px;
  			display: inline-block;
  			margin-bottom: -4px;
  			vertical-align: bottom;
		}		
	}
	p.contact {
		border-top: 1px #d5d5d5 solid;
		padding-top: 25px;
		font-size: 22px;
		line-height: 1.4;
		margin-top: 10px;
		@include break(medium) {
			font-size: 18px;
		}	
		@include break(neutral) {
			font-size: 20px;
		}	
		@include break(x-small) {
			font-size: 16px;
		}	

		a {
			color: #ae0922;
			&.tel {
				color: #2b2b2b;
			}
		}
	}

}


hr {
	margin: 0 0 5px ;
	padding: 0;
	background: #d7d7d7;
	height: 1px;
	width: 100%;
	border: 0; 
}


/**     SUB  P A G E    C O N T E N T          **/
section.content.sub-page-content {
	h1 {
		display: block;
		padding-left: 0;
		background: none;
		white-space: normal;
		span {
			display: inline-block;
			position: relative;
			background: url("../img/bg-h1.svg") no-repeat left 22px;
			padding-left: 86px;
			@include break(medium) {
				background-position: left 8px;
			}	
			&::after {
				content:'';
				background: url("../img/bg-h1.svg") no-repeat left 22px;
				transform: scale(-1, 1);
				display: block;
				float: right;
				width: 86px;
				height: 40px;
			}
			&.left {
				&::after {
					display: none;
				}				
			}
			u {
				text-decoration: none;
				text-transform: uppercase;
			}
		}
	}	
	h1 + h3 {
		margin-top: 0;
		padding-top: 0;
	}
	h3 {
		@include fz(22px, 1.4, #2b2b2b, 400);					
		font-family: $Playfair;
		margin-bottom: 20px;
		padding-bottom: 10px;
		@include break(neutral) {
			font-size: 18px;
		}			
		br {
			@include break(neutral) {
				display: none;
			}	
		}
	}
	h2 {
		@include fz(28px, 1.25, #2b2b2b, 400);					
		margin-bottom: 25px;
		padding-top: 10px;
	}
	h4 {
		@include fz(24px, 1.25, #2b2b2b, 700);					
		margin-bottom: 15px;
		padding-top: 10px;
		padding-bottom: 10px;
		@include break(neutral) {
			font-size: 20px;
		}			
	}
	h5 {
		@include fz(18px, 1.25, #2b2b2b, 700);					
		margin-bottom: 15px;
		padding-top: 10px;
		font-weight: 700;
	}
	h6 {
		@include fz(20px, 1.25, #2b2b2b, 400);					
		margin-bottom: 15px;
		padding-top: 10px;
		@include break(neutral) {
			font-size: 16px;
		}	
		a {
			color: #ae0922;
			&.tel {
				color: #2b2b2b;
				font-weight: 700;
			}
		}
	}

	.inner {
		max-width: 820px;
		margin-left: auto;
		margin-right: auto;
	}
	hr {
		max-width: 530px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
		margin-bottom: 20px;
	}
}
.content-text {
	& > br:first-child {
		display: none;
	}
	p {
		text-indent: 30px;
		&.non-indent {
			text-indent: 0px;
		}
	}
	ul {
		padding-top: 10px;
		padding-bottom: 20px;
		li {
			@include fz(16px, 1.4, #2b2b2b, 400);					
			padding-left: 20px;
			padding-bottom: 10px;
			background: url("../img/list.svg") no-repeat left 6px;
		}
	}

}


/* S I D E B A R   */
.sidebar {
	ul {
		margin: 0 0 35px 0;
		padding:0;
		border: 1px #d5d5d5 solid;
		li {
			a {
				display: block;
				text-decoration: none;
				@include fz(16px, 1.25, #2b2b2b, 400);	
				border-left: 5px #f6f6f6 solid;
				padding-left: 18px;
				padding-top: 8px;
				padding-bottom: 8px;				
				&:hover {
					color: #5d5d5d;
					border-left-color:  #2b2b2b;
				}
				&.current-menu-item {
					color: #202020;
					font-weight: 700;
				}
				@include break(neutral) {
					font-size: 12px;
					padding-left: 10px;
				}	
			}
		}
		li + li {
			border-top: 1px #d5d5d5 solid;
		}
	}
}


.wrap-form {
	background: #eeeeee;
	padding: 10px 24px 40px 24px;
	margin-bottom: 20px;
	@include break(neutral) {
		padding: 8px 15px 25px;
	}	
	h4 {
		@include fz(24px, 1.25, #292c33, 700);					
		font-family: $Lato;
		margin-bottom: 5px;
		padding-bottom: 0;
	}

}


/* B A N N E R S  */

.banners {
	background: #2b2b2b;
	padding: 35px 0 25px;
	@include break(neutral) {
		padding: 25px 0 20px;
	}	
	@include break(mobile) {
		background: #000;
		padding: 20px 0 15px;
	}	
	h1 {
		display: block;
		padding-left: 0;
		background: none;
		color: #fff;
		font-family: $Playfair;
		@include fz(40px, 1.25, #fff, 600);			
		text-align: center;
		padding-bottom: 25px;
		@include break(neutral) {
			font-size: 24px;
		}	
		@include break(mobile) {
			font-size: 22px;
		}	
		span {
			display: inline-block;
			position: relative;
			background: url("../img/bg-h1-white.svg") no-repeat left 20px;
			padding-left: 100px;
			@include break(mobile) {
				background: none;
				padding-left: 0;
			}	
			&::after {
				content:'';
				background: url("../img/bg-h1-white.svg") no-repeat left 20px;
				transform: scale(-1, 1);
				display: block;
				float: right;
				width: 86px;
				height: 40px;
				@include break(mobile) {
					display: none;
				}	
			}
		}		
	}

	h3 {
		padding: 0 0 35px 0 ;
		margin: 0;
		float: left;
		@include fz(24px, 1.25, #fff, 900);			
		max-width: 940px;
		font-family: $Playfair;
		@include break(medium) {
			font-size: 20px;
			max-width: 780px;
		}	
		@include break(neutral) {
			font-size: 15px;
			max-width: 520px;
		}	
		@include break(mobile) {
			font-size: 16px;
			max-width: none;
			text-align: center;
		}	
	}
	a.btn {
		float: right;
		margin-top: 10px;
		@include break(neutral) {
			margin-top: 2px;
		}	
		@include break(mobile) {
			display: none;
		}	
		&:hover {
			background: #000;
		}
	}
	a.banner {
		display: block;
		background: #fff;
		margin-bottom: 25px;
		text-decoration: none;
		color: #2b2b2b;
		overflow: hidden;
		@include link_trans();		
		.inner {
			padding: 20px 18px;
			float: left;
			max-width: 50%;
			position: relative;
			@include break(custom) {
				padding: 18px 18px 0 18px;
			}	
			@include break(medium) {
				padding: 12px 16px 0 16px;
			}	
			@include break(x-small) {
				max-width: 100%;
			}	
			h4 {
				margin: 0;
				padding: 0 0 6px 0;
				@include fz(24px, 1.25, #2b2b2b, 700);			
				@include break(custom) {
					font-size: 20px;
				}	
				@include break(medium) {
					font-size: 16px;
				}	
				@include break(neutral) {
					font-size: 24px;
				}	
				@include break(mobile) {
					font-size: 18px;
				}	
			}
			p {
				@include fz(16px, 1.25, #2b2b2b, 400);			
				@include break(custom) {
					font-size: 15px;
				}	
				@include break(medium) {
					font-size: 14px;
					line-height: 1.1;
				}	
				@include break(neutral) {
					font-size: 16px;
				}	
				@include break(mobile) {
					font-size: 14px;
				}	
			}
		}
		img {
			float: right;
			max-width: 50%;
			@include break(x-small) {
				float: none;
				max-width: 100%;
				width: 100%;
			}	
		}
		span {
			color: #fff;
			text-transform: uppercase;
			font-size: 14px;
			@include break(medium) {
				font-size: 12px;
			}	
			@include break(mobile) {
				display: none;
			}	
			&::after {
				content: '';
				font-family: 'FontAwesome';
				color: #fff;
				font-size: 12px;
				padding-left: 2px;
			}
		}
		em {
			font-style: normal;
			@include fz(94px, 1, #f0f0f0, 900);			
			position: absolute;
			right: 50%;
			bottom:15px;
			font-family: $Lato;
			@include link_trans();		
			@include break(custom) {
				font-size: 60px;
				bottom: 18px;
			}	
			@include break(medium) {
				font-size: 60px;
				bottom: 18px;
			}	
			@include break(mobile) {
				display: none;
			}	

		}

	  &:hover {
	  	background: #be1e2d;
	  	h4, p {
	  		color: #fff;	  		
	  	}
	  	span {
	  		color: #c8c8c8;
	  		&::after {
		  		color: #c8c8c8;
	  		}
	  	}
	  	em {
			color: #f0f0f0;
			opacity: 0.1;
	  	}
	  }
	}
}


/* C O N T A C T S   */
.contacts {
	#button_submit, #fields .submit {
		max-width: 180px;
		float: right;
		@include break(neutral) {
			float: left;
		}	
		@include break(mobile) {
			float: none;
		}	
	}
}


.wrapp-form {
	padding: 8px;
	background: #eee;
	.inner-form {
		border: 1px #fff solid; 
		background: #eee;
		padding: 28px;
		@include break(medium) {
			padding: 16px;
		}
		h3 {
			margin: 0;
			padding: 0 0 10px 0;
			@include fz(26px, 1.25, #292c33, 400);			
			@include break(medium) {
				font-size: 22px;
			}
		}
	}
}


/* G A L L E R Y */
a.gallery {
	display: block;
	border: 1px #d0d0d0 solid;
	padding: 10px;
	background: #fff; 
	margin-bottom: 25px;
	margin-top: 10px;
	position: relative;	
	&::before {
		opacity: 0;
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		@include link_trans();
		background: rgba(2, 99, 175, .2);

	}
	&::after {
		opacity: 0;
		content: '';
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		width: 50px;
		height: 50px;
		@include link_trans();
		font-family: 'FontAwesome';
		text-align: center;
		color: #fff;
		font-size: 30px;
		transform: translate(-50%, -50%);
	}
	&:hover {
		&::before,
		&::after {
			opacity: 1;
		}
	}
	img {
		width: 100%;
	}
}



/**     F O O T E R        F O O T E R         F O O T E R         **/
footer.site-footer {
	padding: 70px 0 55px;
	background: #f5f5f5;
	@include break(neutral) {
		padding:  28px 0 20px;
	}

	div[class*="col-xs"] {
		@include break(x-small) {
			float: none;
			width: 100%;
			text-align: center;
		}		
	}


	h5 {
		margin: 0;
		padding: 0 0 10px 0;
		@include fz(16px, 1.2, #2b2b2b, 700);			
		text-transform: uppercase;
	}
	p {
		@include fz(16px, 1.38, #2b2b2b, 400);	
		@include break(neutral) {
			font-size: 13px;
		}		

	}
	a.tel {
		@include fz(30px, 1.38, #2b2b2b, 700);							
		@include break(neutral) {
			font-size: 24px;
		}		
		@include break(mobile) {
			font-size: 20px;
		}		
	}
	.logo {
		padding-top: 10px;
		@include break(mobile) {
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 180px;
			margin-bottom: 25px;
		}		
	}
}



/*-------------------    Copyright  --------------------*/
.copyright {
	padding: 15px 0;
	background: #d7d7d7;
	p {
		@include fz(14px, 1.2, #595959, 400);			
		margin-bottom: 0;
		padding-bottom: 0;
		padding-top: 5px;
		padding-bottom: 10px;
		@include break(neutral) {
			font-size: 12px;
		}
		@include break(mobile) {
			float: none!important;
			width: 100%;
			text-align: center;
		}

		a {
			color: #595959;
			text-decoration: none;			
			&:hover {
				color: #000;
			}
		}
	}
}	



/**     F O R M    F O R M    F O R M    F O R M    F O R M    F O R M     **/

#ajax-contact-form {
	text-align: left;
	p {
		margin-bottom: 0;
		padding-bottom: 0;
		padding-top: 0;
		span {
			display: block;
			line-height: 1.2;
			@include fz(14px, 1.5, #3a3a3a, 700);
			font-family: $Lato;			
			padding-top: 4px;
			@include break(neutral) {
				font-size: 12px;
			}
		}
	}
	label {
		margin-bottom: 0;
	}

}
#ajax-contact-form textarea,
#ajax-contact-form select,
#ajax-contact-form input {
	padding: 4px 12px 6px;
	background: #fff;
	width: 100%;
	border-radius: 4px;
 	@include fz(14px, 1.5em, #282828, 300);
	margin: 4px 0 12px;
  border: solid 1px #c7cbce;
}
#ajax-contact-form select{
	font-size: 14px;
	padding-left: 8px;
	height: 36px;
	color: #93979b;
}

#ajax-contact-form textarea:active,
#ajax-contact-form input:active,
#ajax-contact-form select:active,
#ajax-contact-form select:focus,
#ajax-contact-form textarea:focus,
#ajax-contact-form input:focus {
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #a6a6a6;
  background: #fff;
	color: #000;
	outline: none;
}
#ajax-contact-form textarea {	height: 70px; }
#ajax-contact-form *::-webkit-input-placeholder {color:#93979b; font-family: $Lato; font-size: 14px;}
#ajax-contact-form *::-moz-placeholder          {color:#93979b; font-family: $Lato; font-size: 14px;}/* Firefox 19+ */
#ajax-contact-form *:-moz-placeholder           {color:#93979b; font-family: $Lato; font-size: 14px;}/* Firefox 18- */
#ajax-contact-form *:-ms-input-placeholder      {color:#93979b; font-family: $Lato; font-size: 14px;}

#ajax-contact-form *:focus::-webkit-input-placeholder {color:#93979b; font-family: $Lato; font-size: 14px;}
#ajax-contact-form *:focus::-moz-placeholder          {color:#93979b; font-family: $Lato; font-size: 14px;}/* Firefox 19+ */
#ajax-contact-form *:focus:-moz-placeholder           {color:#93979b; font-family: $Lato; font-size: 14px;}/* Firefox 18- */
#ajax-contact-form *:focus:-ms-input-placeholder      {color:#93979b; font-family: $Lato; font-size: 14px;}

#ajax-contact-form #zip {
	float: right;
	width: 28%
}
#ajax-contact-form #mobile {}

#button_submit,
#fields .submit {
	@include link_trans();
	@include fz(18px, 36px, #fff, 300);
	margin: 0;
	text-decoration: none;
	background: none;
	position: relative;
	z-index: 10;
	display: inline-block;
 	padding: 6px 10px;
	border-radius: 0;
	text-align: center;
	background: #292c33;
	width: 100%;
	border:none;
	text-transform: uppercase;
	border: none!important;

	@include break(medium) {
		font-size: 14px;
	}
	@include break(neutral) {
		max-width: 240px;
		margin-left: auto;
		margin-right: auto;
		float: none;
	}
	@include break(mobile) {
		line-height: 20px;
	}
	@include break(x-small) {
		font-size: 11px;
	}
	&:hover {
		color: #fff;
		background: #2b2b2b;
	}
}

.notification_ok {
	padding-bottom: 15px;
	color: #000;
	line-height: 1.5em;
	font-size: 18px;
	text-align: left;
	padding-top: 15px;
}

.notification_error {
	color: #e54423;
	clear: both;
	text-align: left!important;
	font-size: 12px;
	line-height: 1.5em;
	font-weight: 700;
}
#note {
	clear: both;
}
#rc-imgelect, .g-recaptcha {
	width: 250px;
  transform:scale(0.82);
  transform-origin:0 0;
  float: left;
	@include break(custom){
	  transform:scale(0.74);
	  transform-origin:0 0;
	}
	@include break(medium){
	  transform:scale(0.60);
	  transform-origin:0 0;
	}
	@include break(neutral){
	  transform:scale(.8);
	  transform-origin:0 0;
	}
	@include break(x-small){
		float: none;
	}

}
img {		height: auto;		max-width:100%;	}
.clearfix {	clear:both;}

#ajax-contact-form label.error {
	color: #f00;
	font-size: 12px;
	margin-top: -8px;
	display: inline-block;
	font-weight: 400;
	clear:both;
}

.mobile-none {
	@include break(neutral){
		display: none;
	}
}
div#button_submit {
	padding-left: 10px;
	padding-right: 10px;
	font-size: 12px;
}


/**     
mobile menu   mobile menu    mobile menu   mobile menu      mobile menu        **/

//colors
$color_0: rgba(255, 255, 255, 0.75);
$color_btn: #222;
$color_text: #fff;
$color_shadow: #000;
$color_hover_bg: #ccc;
$color_hover_text: #222;

.slicknav_menu {
	font-size: 16px;
	box-sizing: border-box;
	background: #2b2b2b;
	display: none;
	z-index: 200;
	position: fixed;
	top: 0;
	width: 100%;
	left:0;
	//height: 41px;
	@include break(mobile){
		display: block;
	}
	.phone-mobile {
		@include fz(16px, 28px, #fff, 300);
		float: left;
		padding-left: 10px;
		padding-top: 6px;
		a {
			@include fz(16px, 28px, #fff, 300);
		}
		span {
			@include break(x-small){
				display: none;
			}
		}
	}
	* {		box-sizing: border-box;	}
	.slicknav_menutxt {
		color: $color_text;
		font-weight: bold;
		text-shadow: 0 1px 3px $color_shadow;
	}
	.slicknav_icon-bar {
		background-color: #f5841f;
	}
}
.slicknav_nav {
	color: $color_text;
	margin: 0;
	padding: 0;
	font-size: 0.875em;
	list-style: none;
	overflow: hidden;
	background: #000;
	z-index:9999;
	.slicknav_row {
		&:click,
		&:active,
		&:focus {
			color: #be1e2d;
			outline:none;
		}
	}
	a {
			@include fz(16px, 40px, #fff, 400);
			text-align: left;
			text-decoration: none;
			border-bottom: 1px rgba(255,255,255,.2) dashed;
			padding-bottom: 0;
			text-transform: uppercase;			
			padding-left: 20px;
			@include link_trans();
			&:hover {
				color: #be1e2d;
			}
		& + i {
			@include break(mobile) {
				display: none;
			}
		}
	}
	a.btn,
	a.btn:active,
	a.btn:focus {
		background: none;
		border:0;
		border-top: 1px rgba(255,255,255,.2) dashed;
		border-bottom: 1px rgba(255,255,255,.2) dashed;
		text-transform: capitalize;		
		border-radius: 0;
		padding-top: 0;
	}

	a > a {
		//border-top: 0;
	}
	.current-menu-item a {		color: #fff;	}
	.slicknav_txtnode {		margin-left: 15px;	}
	.slicknav_item a {		padding: 0;		margin: 0;	}
	.slicknav_parent-link a {		padding: 0;		margin: 0;	}
}
.slicknav_brand {
	color: $color_text;
	font-size: 18px;
	line-height: 30px;
	padding: 7px 12px;
	height: 44px;
}
.slicknav_btn {
	span {
		display: none;
	}
	display: block;
	margin: 0;
	padding: 0;
	text-decoration: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	background: url("../img/mobile-button.png") 50% 2px no-repeat;
	background-size: 39px auto;
	float: left;
	margin: 0;
	padding: 0;
	top: 1px;
	left:10px;
	position: relative;
	@include link_trans();
	&.slicknav_open {
		background-position: 50% -34px;
	}
}

.slicknav_menu {
	*zoom: 1;
	.slicknav_menutxt {
		display: block;
		line-height: 1.188em;
		float: left;
	}
	.slicknav_icon {
		float: left;
    width: 1.125em;
    height: 0.875em;
		margin: 0.188em 0 0 0.438em;
        &:before {
            background: transparent;
            width: 1.125em;
            height: 0.875em;
            display: block;
            content: "";
            position: absolute;
        }
	}
	.slicknav_no-text {
		margin: 0;
	}
	.slicknav_icon-bar {
		display: block;
		width: 1.125em;
		height: 0.125em;
		-webkit-border-radius: 1px;
		-moz-border-radius: 1px;
		border-radius: 1px;
		-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
	}
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.slicknav_nav {
	clear: both;
	ul {
		list-style: none;
		overflow: hidden;
		padding: 10px 0 10px 0;
		margin: 0;
		//background: #0a4490;
		li {
			a {
				text-transform: capitalize;
				line-height: 24px;
				color: #fff;
				border: none;
				font-size: 15px;
				font-weight: 400;
				padding-left: 30px;
				display: block;
				&:hover {
					color: #be1e2d;
				}
			}
		}
	}
	.slicknav_arrow {
		font-size: 0.8em;
		color: #be1e2d;
		font-family: 'FontAwesome';
		float: right;
		margin-right: 20px;
	}
	.slicknav_item {
		cursor: pointer;
		a {
			display: inline;
			border-bottom: none;
			&:click,
			&:active,
			&:focus {
				color: #be1e2d;
				outline:none;
			}

		}
	}
	.slicknav_row {
		display: block;
		&:click,
		&:active,
		&:focus {
			color: #fff;
			outline:none;
		}
	}
	a {		display: block;	}
	.slicknav_parent-link a {
		display: inline;
	}
}


/**     O T H E R       **/
a{color:#00215c; text-decoration:underline;}
a:focus{color:#00215c;text-decoration:none;}
a:hover{text-decoration:none;color:#000}
.center {	text-align: center;	}
.scrolloff {  pointer-events: none;}
.map {
	position: relative;
	padding-bottom: 50%;
	padding-top: 30px;
	height: 0;
	background: #ccc;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
	}
}
ul.list {
	padding: 0 0 20px 0;
	margin: 16px 0 0 12px;
	overflow: hidden;
	li {
		padding-left: 30px;
		position: relative;		
		padding-bottom: 8px;
		@include fz(16px, 1.4, #292b2e, 400);
		font-family: $Lato;

		ul {
		  padding-top: 15px;
		}

	  @include break(neutral) {		}

	  &::after {
	  	//background: #ed6223;
	  	width: 9px;
	  	height: 9px;
	  	position: absolute;
	  	left: 2px;
	  	top: -1px;
	  	border-radius: 50%;
	  	content: '';
	  	display: block;	  	
	  	font-size: 18px;
	  	font-family: "FontAwesome";
	  	color: #ce0316;
	  }
	}
}
.right {
	float: right;
	@include break(mobile) {
		float: none;
	}
}
.paddcontent {
	padding-bottom: 40px;
	@include break(mobile) {
		padding-bottom: 10px;
	}	
}
/* Buttons */
.btn:active, .btn:focus,
.btn {
	@include link_trans();
	@include fz(14px, 1.4em, #fff, 700);
	margin: 0;
	text-decoration: none;
	position: relative;
	z-index: 10;
	display: inline-block;
 	padding: 16px 36px;
	border-radius: 0;
	text-align: center;
	background-color: #ce0316;
	text-transform: uppercase;
	text-shadow:none;	
	transform:  skew(-10deg);
	letter-spacing: 0.8px;
	@include break(custom) {
	 	padding: 16px 30px;
	}				
	@include break(medium) {
		padding: 6px 12px;
		font-size: 14px;
	}
	@include break(mobile) {
		padding: 12px 12px;
		transform:  skew(0);
	}
	@include break(x-small) {
	}
	span {
		transform:  skew(10deg);
		display: block;
	}
	&:hover {
		color: #fff;
		background: #2b2b2b;
	}	

}

/* G A L L E R Y  */

/* House plans page.
------------------------------------------------------------ */
.content-text {
	.filter {
	  text-align: center;
	  font-size: 0;
	  padding-bottom: 0;
	  margin-bottom: 30px;
	  li {
	      display: inline-block;    
		  border-top: 1px #cccccc solid;
		  border-bottom: 1px #cccccc solid;
		  background: none!important;
		  margin-bottom: 0;
		  padding: 0;
	      &::before,
	      &::after {
	      	display: none;
	      }
	    span {
	      display: block;
	      padding: 0 4px;
	      font-size: 14px;
	      color: #707070;
	      line-height: 40px;
	      text-transform: uppercase;
	      cursor: pointer;
	      border-bottom: 3px transparent solid;
	      @include break(medium) {
	        font-size: 13px;
	        padding: 0 10px;
	      }      
	      @include break(neutral) {
	        font-size: 12px;
	      }      
	      &:hover {
	        border-color:#be1e2d;
	      }
	      &.active {
	        font-weight: 700;
	      }
	    }
	  }
	  li + li {
	  	padding-left: 12px;
	  }
	}

}

.blocks {

  .inner {
    border-bottom: 1px solid #d6d6d6;
    position: relative;
    display: block;
    margin-bottom: 30px;
    a {
    	display: block;
    	text-decoration: none;
		@include fz(14px, 1.4em, #2b2b2b, 400);
	    &::after {
	      content: url('../img/zoom.svg');
	      position: absolute;
	      z-index: 1;
	      transition: .3s;
	      opacity: 0;
	      position: absolute;
	      left:50%;
	      top: 50%;
	  	  transform: translate(-50%, -50%);
	    }
	    p {
	    	padding-bottom: 10px;
	    	padding-top: 10px;
	    	margin-bottom: 0;
	    	text-indent: 0;
	    	padding-left: 0px;
		    transition: .3s;
	    }

	    &:hover {
	    	background: #f3f3f3;
	    	p {
	    		padding-left: 15px;
	    	}
	      &::after {
	        opacity: 1;
	      }
	    }

    }

  }
}

.smallpadd {
	padding-bottom: 10px;
}


.red {	color: #ba0003;}
a[href*="tel"]{text-decoration:none; cursor: default;};
::selection{background: #ededed!important;color:#000000};
::-moz-selection{background: #ededed!important;color:#000000};